import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import Main from '../components/Main'
import '../styles/base.scss';
import '../styles/whitelist.scss';


function IndexPage(){
  const Widget =()=>{
    return(
      <Helmet>
       <script src="https://cdnjs.cloudflare.com/ajax/libs/babel-standalone/6.21.1/babel.min.js" charset="utf-8"></script>
       <script src="https://unpkg.com/react@18/umd/react.development.js" crossorigin></script>
       <script src="https://unpkg.com/react-dom@18/umd/react-dom.development.js" crossorigin></script>
       <script src="https://calm-bayou-11404.herokuapp.com/popup" defer></script> 
     </Helmet>
    )
  }

  const WidgetTarget=()=>{
    return(
      <div id='widgetTarget'></div>
    )
  }


  return(
    <div className="container">
    <Header/>
    <Main/>
    <WidgetTarget/>
    <Widget/>
  </div>
  )
}

export default IndexPage

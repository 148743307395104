import React, {useState, useRef, useEffect} from 'react';
import './Main.scss';
import { StaticImage } from "gatsby-plugin-image";
import { AiOutlineClose } from 'react-icons/ai';

import gsap from 'gsap';

function Main() {

  const [hovered, setHovered]= useState("");

  useEffect(() => {
    if(hovered){
      setTimeout(() => {
        setHovered('');
      }, 3000);
    }

  }, [hovered]);

  let cases =[
    {id: 1, "class":"active", "text":"Заявление на алименты", "sub": ""},
    {id:2 , "class":"blocked", "text":"Штрафы ДТП", "sub": "В разработке"},
    {id:3 , "class":"blocked", "text":"Возмещение ущерба", "sub": "В разработке"},
    {id: 4, "class":"blocked", "text":"Долевое строительство", "sub": "В разработке"},
  ];


  const Popup =({text})=>{
    console.log(text)
    return(
      <div className='pop_overlay'>
        <div className='pop'>
          <AiOutlineClose onClick={()=>setHovered(false)}/>
        <div className='pop_wrapper'>
        <p>{text}</p>
        <div className='image-wrapper'>
          <StaticImage src="../../images/developer.webp" alt="A developer" />
        </div>
        </div>
      </div>
      </div>
    )
  }

 return (
  <div className='wrapper wrapper_main'>
      <div className='wrapper_main_hero'>
        <div className='wrapper_main_hero_left'>
        <h1>Составить иск не выходя из дома</h1>
        <div className='ball' />
        <ul>
         {cases.map((item) => 
         <li key = {item.id} className={item.class} onClick={()=>setHovered(item.class)}>{item.text}</li>
         )}
        </ul>
        </div>
        <StaticImage src="../../images/arrow.png" alt="An arrow"/>
        <div className='wrapper_main_hero_right'>
        </div>
      </div>
      <div className='bubble_container'>
        <div class="bubl1"></div>
        <div class="bubl2"></div>
        <div class="bubl3"></div>
        <div class="bubl4"></div>
      </div>
      {hovered =="blocked" && <Popup text="Этот раздел в разработке"/>}
    </div>
  )
}

export default Main